import * as React from 'react';

import { Typography, Button } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import HomeIcon from '@material-ui/icons/Home';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404 - Not found" />
    <div style={{ marginTop: '6rem' }} />

    <Container variant="wide">
      <Typography variant="h1" color="primary">
        Not found
      </Typography>

      <Typography variant="body1" gutterBottom>
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>

      <Button
        variant="text"
        onClick={() => {
          navigate('/');
        }}
      >
        <HomeIcon /> Back to Home
      </Button>
      <div style={{ marginTop: '2rem' }} />
    </Container>
  </Layout>
);

export default NotFoundPage;
